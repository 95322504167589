<template>
  <div id="lineChart" :style="{width: '98%', height: '280px'}"></div>
</template>

<script>
export default {
  props: ["name", "tableData"],
  mounted() {
    this.getEchart();
  },
  watch: {
    tableData() {
      this.getEchart();
    }
  },
  methods: {
    getEchart() {
      let time = this.tableData.map(item => item.time); //X轴坐标数据
      let wx = this.tableData.map(item => item.wx); //wx数据
      let ali = this.tableData.map(item => item.ali);
      // let yz = this.tableData.map(item => item.yz); //wx数据
      let total = this.tableData.map(item => item.total);
      const echarts = require("echarts");
      const getlinechart = echarts.init(document.querySelector("#lineChart"));
      let option = {
        title: {
          text: this.name,
          x: "20px",
          textStyle: {
            fontSize: 14
          }
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          //图例
          color: ["#F58080", "#47D8BE", "#F9A589"],
          show: true
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          //保存为图片
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ["toolbox"],
              pixelRatio: 2
            }
          }
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#6A989E"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(135,140,147,0.8)"
            }
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#333", // x轴颜色
              fontWeight: "600",
              fontSize: "11",
              lineHeight: 22
            }
          },
          data: time
        },
        yAxis: {
          type: "value",
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#6A989E"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(135,140,147,0.8)"
            }
          },
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#333", // x轴颜色
              fontWeight: "600",
              fontSize: "11",
              lineHeight: 22
            }
          }
        },
        series: [
          {
            name: "微信",
            type: "line",
            data: wx,
            lineStyle: {
              normal: {
                width: 5,
                color: {
                  type: "linear",

                  colorStops: [
                    {
                      offset: 0,
                      color: "#FFCAD4" // 0% 处的颜色
                    },
                    {
                      offset: 0.4,
                      color: "#F58080" // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#F58080" // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: "rgba(245,128,128, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7
              }
            },
            itemStyle: {
              normal: {
                color: "#F58080",
                borderWidth: 10,
                /*shadowColor: 'rgba(72,216,191, 0.3)',
                     shadowBlur: 100,*/
                borderColor: "#F58080"
              }
            }
          },
          {
            name: "支付宝",
            type: "line",
            data: ali,
            lineStyle: {
              normal: {
                width: 5,
                color: {
                  type: "linear",

                  colorStops: [
                    {
                      offset: 0,
                      color: "#AAF487" // 0% 处的颜色
                    },
                    {
                      offset: 0.4,
                      color: "#47D8BE" // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#47D8BE" // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: "rgba(71,216,190, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7
              }
            },
            itemStyle: {
              normal: {
                color: "#AAF487",
                borderWidth: 10,
                /*shadowColor: 'rgba(72,216,191, 0.3)',
                     shadowBlur: 100,*/
                borderColor: "#AAF487"
              }
            }
          },
          // {
          //   name: "云纵",
          //   type: "line",
          //   data: yz,
          //   lineStyle: {
          //     normal: {
          //       width: 5,
          //       color: {
          //         type: "linear",

          //         colorStops: [
          //           {
          //             offset: 0,
          //             color: "#F6D06F" // 0% 处的颜色
          //           },
          //           {
          //             offset: 0.4,
          //             color: "#F9A589" // 100% 处的颜色
          //           },
          //           {
          //             offset: 1,
          //             color: "#F9A589" // 100% 处的颜色
          //           }
          //         ],
          //         globalCoord: false // 缺省为 false
          //       },
          //       shadowColor: "rgba(249,165,137, 0.5)",
          //       shadowBlur: 10,
          //       shadowOffsetY: 7
          //     }
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: "#F6D06F",
          //       borderWidth: 10,
          //       /*shadowColor: 'rgba(72,216,191, 0.3)',
          //            shadowBlur: 100,*/
          //       borderColor: "#F6D06F"
          //     }
          //   }
          // },
          {
            name: "总计",
            type: "line",
            data: total,
            lineStyle: {
              normal: {
                width: 5,
                color: {
                  type: "linear",

                  colorStops: [
                    {
                      offset: 0,
                      color: "#ADF7F7" // 0% 处的颜色
                    },
                    {
                      offset: 0.4,
                      color: "#5FF7FC" // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#05E5EA" // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                },
                shadowColor: "rgba(44,245,250, 0.5)",
                shadowBlur: 10,
                shadowOffsetY: 7
              }
            },
            itemStyle: {
              normal: {
                color: "#00F8FF",
                borderWidth: 10,
                /*shadowColor: 'rgba(72,216,191, 0.3)',
                     shadowBlur: 100,*/
                borderColor: "#00F8FF"
              }
            }
          }
        ]
      };
      getlinechart.setOption(option);
      window.addEventListener("resize", function() {
        getlinechart.resize();
      });
    }
  }
};
</script>

<style>
</style>